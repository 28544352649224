<template>
  <div class="home">
    <div class="">
      <h1>Willkommen auf meiner Pers&ouml;nlichen Webseite</h1>
      <p>Unter About k&ouml;nnt ihr einiges &uuml;ber mich lesen</p>
      <p>Unter Projects findet ihr auch die Projekte an denen ich gerade am arbeiten bin</p>
      <p>Unter FOSS findet ihr alles zu meinem Projekt Free and Open Source Software</p>
    </div>
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Created with Vue"/>
  </div>
</template>

<style>
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  img {
    height: 100px;
  }
</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
