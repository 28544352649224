<template>
  <header>
    <div class="nav-wrapper">
      <div class="logo-container">
        Personal
      </div>
      <nav>
        <input class="hidden" type="checkbox" id="menuToggle">
        <label class="menu-btn" for="menuToggle">
          <div class="menu"></div>
          <div class="menu"></div>
          <div class="menu"></div>
        </label>
        <div class="nav-container">
          <ul class="nav-tabs">
            <router-link class="nav-tab" to="/">Home</router-link>
            <router-link class="nav-tab" to="/about">About</router-link>
          </ul>
        </div>
      </nav>
    </div>
  </header>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <router-view class="main"/>
  <footer>
    test
  </footer>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 10px;
}
body {
  background: black;
  color: white;
  font-size: 1.8rem;
}
#app {
  font-family: "Courier New", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: bold;
}

h1 {
  font-size: 3.6rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.4rem;
}
h1, h2, h3, p {
  padding: 1rem 0;
}

.main {
  min-height: 100svh;
}

.nav-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 90%;
  height: 80px;
  border-radius: 0 0 15px 15px;
  padding: 0 25px;
  z-index: 2;
  background: transparent;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 60px;
}
.nav-tabs {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  list-style: none;
}
.nav-tab:not(:last-child) {
  padding: 10px 25px;
  margin: 0;
  border-right: 1px solid #eee;
}
.nav-tab:last-child {
  padding: 10px 0 0 25px;
}
.nav-tab,
.menu-btn {
  cursor: pointer;
}
a.nav-tab {
  color: white;
}
a.router-link-exact-active {
  color: red;
}
.hidden {
  display: none;
}
@media screen and (max-width: 800px) {
  .nav-container {
    position: fixed;
    display: none;
    overflow-y: auto;
    z-index: -1;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    background: black;
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
  }
  .nav-tabs {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 80px;
    width: 100%;
  }
  .nav-tab:not(:last-child) {
    padding: 20px 25px;
    margin: 0;
    border-right: unset;
    border-bottom: 1px solid #f5f5f5;
  }
  .nav-tab:last-child {
    padding: 15px 25px;
  }
  .menu-btn {
    position: relative;
    display: block;
    margin: 0;
    width: 20px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
    border-radius: 10px;
  }
  .menu-btn .menu {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #fff;
  }
  .menu-btn .menu:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
  }
  .menu-btn .menu:nth-child(3) {
    margin-top: 4px;
  }
  #menuToggle:checked + .menu-btn .menu {
    transition: transform 0.2s ease;
  }
  #menuToggle:checked + .menu-btn .menu:nth-child(1) {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  #menuToggle:checked + .menu-btn .menu:nth-child(2) {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }
  #menuToggle:checked + .menu-btn .menu:nth-child(3) {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  #menuToggle:checked ~ .nav-container {
    z-index: 1;
    display: flex;
    animation: menu-slide-left 0.3s ease;
  }
  @keyframes menu-slide-left {
    0% {
      transform: translateX(200px);
    }
    to {
      transform: translateX(0);
    }
  }
}
</style>
